/* eslint-disable react/function-component-definition */
import { gql, useMutation, useQuery } from "@apollo/client";
import { TextField, Skeleton } from "@mui/material";
import { InfoOutlined, WarningTwoTone } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import tw, { styled } from "twin.macro";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { subYears } from "date-fns";
import { arrayOf, number, shape, string } from "prop-types";

import { EDIT_USER_MUTATION } from "../utils/mutations";
import { ScreenSizeContext } from "../utils/screensize-provider";
import { JimButton, JimDialog, TextButton, TilePrim } from "./util-components";
import { arrayCreator, formatDE } from "../utils/util-functions";

const USER_DATA_QUERY = gql`
  query GetUser {
    getUser {
      id
      # status
      first_name
      last_name
      email
      date_of_birth
      phone
      phone2
      address {
        zip
        city
        street
        number
      }
    }
  }
`;

const labelLib = {
  first_name: "Vorname",
  last_name: "Nachname",
  zip: "PLZ",
  city: "Stadt",
  street: "Straße",
  number: "Nr.",
  // phone: "primär",
  // phone2: "sekundär",
};

const ContentAreaWrapper = tw.div`flex flex-col p-4 space-y-2`;

// const VerifiedTag = styled.div(({ status }) => [
//   tw`flex items-center text-sm font-semibold border-2 border-yellow-500 text-yellow-500 rounded-md px-1`,
//   status === "ACTIVE" && tw`border-green-500 text-green-500`,
// ])

const TextFieldWithWidth = styled(TextField)(({ width }) => [
  `width: ${width}%;`,
]);

const ContentArea = ({ title, items, widthArr, userData }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formVars, setFormVars] = useState(null);
  const [editUser] = useMutation(EDIT_USER_MUTATION);

  const getOriginalFormVars = () => {
    const varObj = {};
    if (title === "Geburtsdatum") {
      varObj.date_of_birth = new Date(
        parseInt(userData.getUser.date_of_birth, 10)
      );
    } else if (title === "Adresse") {
      items.forEach(item => {
        varObj[item] = userData.getUser.address[item];
      });
    } else {
      items.forEach(item => {
        varObj[item] = userData.getUser[item];
      });
    }
    return varObj;
  };

  useEffect(() => {
    if (userData && userData.getUser) {
      setFormVars(getOriginalFormVars());
    }
  }, [userData]);

  const handleSave = e => {
    e.preventDefault();

    const mutationVars = { id: userData.getUser.id };
    if (title === "Geburtsdatum") {
      mutationVars.date_of_birth = formVars.date_of_birth.getTime().toString();
    } else if (title === "Adresse") {
      mutationVars.address = {};
      Object.keys(formVars).forEach(item => {
        mutationVars.address[item] = formVars[item].trim();
      });
    } else {
      Object.keys(formVars).forEach(item => {
        mutationVars[item] = formVars[item].trim();
      });
    }

    editUser({
      variables: mutationVars,
      optimisticResponse: {
        __typename: "Mutation",
        editUser: {
          ...mutationVars,
          status: "PENDING",
        },
      },
    });
    setIsEditing(false);
  };

  const handleEditToggle = () => {
    if (isEditing) {
      setIsEditing(false);
      setFormVars(getOriginalFormVars());
    } else {
      setIsEditing(true);
    }
  };

  const getWarning = () => {
    let isEmpty = false;
    Object.keys(formVars).forEach(key => {
      if (formVars[key] === "") {
        isEmpty = true;
      }
    });
    if (isEmpty)
      return (
        <div tw="text-base text-yellow-500 ml-2">
          <WarningTwoTone fontSize="inherit" />
        </div>
      );
    return null;
  };

  return (
    <ContentAreaWrapper>
      {formVars ? (
        <>
          <div tw="flex items-center justify-between">
            <div tw="flex items-center">
              {title}
              {getWarning()}
            </div>
            <TextButton onClick={handleEditToggle}>
              {isEditing ? "abbrechen" : "bearbeiten"}
            </TextButton>
          </div>
          {isEditing ? (
            <>
              {items[0] === "date_of_birth" ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    id="date-of-birth-input"
                    renderInput={params => <TextField {...params} />}
                    value={formVars.date_of_birth}
                    onChange={date => setFormVars({ date_of_birth: date })}
                    inputFormat="dd.MM.yyyy"
                    minDate={subYears(new Date(), 99)}
                    maxDate={subYears(new Date(), 16)}
                    openTo="year"
                  />
                </LocalizationProvider>
              ) : (
                <>
                  <div tw="flex space-x-4 pt-2">
                    {arrayCreator(0, 1, 1).map(
                      ind =>
                        items[ind] && (
                          <TextFieldWithWidth
                            width={widthArr[ind]}
                            key={`${items[ind]}-input`}
                            id={`${items[ind]}-input`}
                            value={formVars[items[ind]]}
                            label={labelLib[items[ind]]}
                            variant="outlined"
                            onChange={e =>
                              setFormVars({
                                ...formVars,
                                [items[ind]]: e.target.value,
                              })
                            }
                          />
                        )
                    )}
                  </div>
                  {items[2] && (
                    <div tw="flex space-x-4 pt-2">
                      {arrayCreator(2, 3, 1).map(ind => (
                        <TextFieldWithWidth
                          width={widthArr[ind]}
                          key={`${items[ind]}-input`}
                          id={`${items[ind]}-input`}
                          value={formVars[items[ind]]}
                          label={labelLib[items[ind]]}
                          variant="outlined"
                          onChange={e =>
                            setFormVars({
                              ...formVars,
                              [items[ind]]: e.target.value,
                            })
                          }
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
              <div tw="flex justify-start pt-2">
                <JimButton variant="primary" onClick={handleSave}>
                  speichern
                </JimButton>
              </div>
            </>
          ) : (
            <div tw="font-semibold">
              {items.length > 2
                ? `${formVars[items[0]]} ${formVars[items[1]]}${
                    (formVars[items[0]] || formVars[items[1]]) &&
                    (formVars[items[2]] || formVars[items[3]]) &&
                    ","
                  } ${formVars[items[2]]} ${formVars[items[3]]}`
                : items[0] === "date_of_birth"
                ? formatDE(formVars.date_of_birth, "dd.MM.yyyy")
                : items.map(item => formVars[item] && `${formVars[item]} `)}
            </div>
          )}
        </>
      ) : (
        <>
          <Skeleton animation="wave" width={40} />
          <div tw="flex space-x-2">
            <Skeleton animation="wave" width={80} />
            {items[1] && <Skeleton animation="wave" width={60} />}
          </div>
        </>
      )}
    </ContentAreaWrapper>
  );
};
ContentArea.propTypes = {
  title: string.isRequired,
  items: arrayOf(string).isRequired,
  widthArr: arrayOf(number).isRequired,
  userData: shape({
    getUser: shape({
      id: string.isRequired,
      first_name: string,
      last_name: string,
      email: string,
      date_of_birth: string,
      phone: string,
      address: shape({
        zip: string,
        city: string,
        street: string,
        number: string,
      }),
    }),
  }),
};
ContentArea.defaultProps = {
  userData: {},
};

const InfoText = () => (
  <>
    <div>
      Deine hier gemachten Angaben werden genutzt, um dich zu anstehenden
      Einsätzen kontaktieren zu können. Achte deshalb darauf, dass insbesondere
      deine <strong>Kontaktdaten korrekt</strong> sind.
    </div>
    <div>
      Wir nutzen deine Daten ausschließlich zur Verifizierung und zur
      Kontaktaufnahme bezüglich deiner Einsätze.
    </div>
  </>
);

const ProfileDataTile = () => {
  const screen = useContext(ScreenSizeContext);
  const { data: userData } = useQuery(USER_DATA_QUERY);
  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      <TilePrim>
        <div tw="flex items-center">
          {/* <div tw="flex flex-col items-start md:(flex-row items-center space-x-8)">
            {userData && (
            <VerifiedTag status={userData.getUser.status}>
              {userData.getUser.status === "ACTIVE" && (
                <>
                  verifiziert
                  <GoVerified tw="text-base ml-1" />
                </>
              )}
              {userData.getUser.status === "PENDING" && (
                <>
                  Prüfung ausstehend
                  <GoUnverified tw="text-base ml-1" />
                </>
              )}
            </VerifiedTag>
          )}
          </div> */}
          {screen.xsDown && (
            <TextButton onClick={() => setShowInfo(true)}>
              <InfoOutlined fontSize="inherit" />
            </TextButton>
          )}
        </div>
        <div tw=" grid grid-cols-1 md:grid-cols-2 gap-8">
          <div tw="flex flex-col divide-y divide-prim-100">
            <ContentArea
              title="Name"
              items={["first_name", "last_name"]}
              widthArr={[50, 50]}
              userData={userData}
            />
            <ContentArea
              title="Geburtsdatum"
              items={["date_of_birth"]}
              widthArr={[100]}
              userData={userData}
            />
            <ContentArea
              title="Adresse"
              items={["street", "number", "zip", "city"]}
              widthArr={[70, 30, 40, 60]}
              userData={userData}
            />
            <ContentArea
              title="E-Mail"
              items={["email"]}
              widthArr={[100]}
              userData={userData}
            />
            <ContentArea
              title="Telefonnummer"
              items={["phone"]}
              widthArr={[100]}
              userData={userData}
            />
          </div>
          {screen.smUp && (
            <div tw="flex items-start">
              <div tw="flex flex-col space-y-1 m-6 py-4 px-6 border border-prim-100 rounded-lg text-prim-500">
                <InfoOutlined tw="mb-2" />
                <InfoText />
              </div>
            </div>
          )}
        </div>
      </TilePrim>
      <JimDialog open={showInfo} handleClose={() => setShowInfo(false)}>
        <div tw="flex flex-col space-y-1 text-prim-500">
          <InfoText />
        </div>
      </JimDialog>
    </>
  );
};

export default ProfileDataTile;
