const { gql } = require("@apollo/client")

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $last_name: String
    $first_name: String
    $email: String!
    $date_of_birth: String
    $phone: String
    $phone2: String
    $address: AddressInput
  ) {
    createUser(
      last_name: $last_name
      first_name: $first_name
      email: $email
      date_of_birth: $date_of_birth
      phone: $phone
      phone2: $phone2
      address: $address
    ) {
      first_name
      last_name
      email
      date_of_birth
      phone
      phone2
      address {
        zip
        city
        street
        number
      }
    }
  }
`

export const EDIT_USER_MUTATION = gql`
  mutation EditUser(
    $id: ID!
    $last_name: String
    $first_name: String
    $email: String
    $date_of_birth: String
    $phone: String
    $phone2: String
    $address: AddressInput
  ) {
    editUser(
      id: $id
      last_name: $last_name
      first_name: $first_name
      email: $email
      date_of_birth: $date_of_birth
      phone: $phone
      phone2: $phone2
      address: $address
    ) {
      id
      __typename
      first_name
      last_name
      email
      date_of_birth
      phone
      phone2
      address {
        zip
        city
        street
        number
      }
    }
  }
`

export const EDIT_USER_TIMEPREF_MUTATION = gql`
  mutation EditUser($id: ID!, $time_preference: TimePreferenceInput) {
    editUser(id: $id, time_preference: $time_preference) {
      id
      __typename
      time_preference {
        __typename
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
  }
`

export const ACCEPT_JOB_MUTATION = gql`
  mutation AcceptJobInstance($id: ID!) {
    acceptJobInstance(jobInstance: $id) {
      id
    }
  }
`

export const UNACCEPT_JOB_MUTATION = gql`
  mutation UnacceptJobInstance($id: ID!) {
    unacceptJobInstance(jobInstance: $id) {
      id
    }
  }
`

export const CREATE_JOBINSTANCES_MUTATION = gql`
  mutation CreateJobInstances(
    $department: ID!
    $start_time: Float!
    $end_time: Float!
    $job: ID!
    $pay: Float!
    $length: Float!
    $description: String
    $num_req_workers: Int!
    $worker: ID
  ) {
    createJobInstances(
      department: $department
      start_time: $start_time
      end_time: $end_time
      job: $job
      pay: $pay
      length: $length
      description: $description
      num_req_workers: $num_req_workers
      worker: $worker
    ) {
      id
      job {
        id
        title
      }
      status
      department {
        id
        name
      }
      start_time
      end_time
      length
      pay
      worker {
        id
        first_name
        last_name
      }
    }
  }
`

export const CANCEL_JOBINST_MUTATION = gql`
  mutation CancelJobInstance($id: ID!) {
    cancelJobInstance(jobInstance: $id) {
      id
    }
  }
`
