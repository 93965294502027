/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext } from "react";
// import { AddRounded } from "@mui/icons-material"
import tw from "twin.macro"; // eslint-disable-line no-unused-vars
// import { Link } from "gatsby"

import { PageTitleContext } from "../utils/pagetitle-provider";
import ProfileDataTile from "../components/ProfileDataTile";
import withRoles from "../utils/with-roles";
import JimHelmet from "../components/JimHelmet";
import { ContentWithoutUtilsBar } from "../components/util-components";
// import ProfileContractsTile from "../components/ProfileContractsTile";

const PersonalData = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Persönliche Daten"), []);

  return (
    <>
      <JimHelmet path="personal-data" />
      <ContentWithoutUtilsBar>
        {/* <div tw="flex justify-end space-x-4">
        <Link
          tw="flex items-center py-1 px-2 bg-blue-300 rounded-full tracking-wider font-semibold text-blue-800 opacity-80 hover:(opacity-100 shadow-md) cursor-pointer transition duration-200"
          to="/create-facility"
        >
          <AddRounded tw="text-lg mr-1" />
          Einrichtung hinzufügen
        </Link>
      </div> */}
        <ProfileDataTile />
        {/* <ProfileContractsTile /> */}
        {/* <ProfileExpTile /> */}
      </ContentWithoutUtilsBar>
    </>
  );
};

export default withRoles(PersonalData, "WORKER");
